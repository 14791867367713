<template>
    <div>
        <div class="modal fade" id="modalShow" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"
             style="position: fixed;">
            <button type="button" class="closeX" id="closeModal" data-dismiss="modal" aria-label="Close"
                    hidden></button>
            <div class="modal-dialog lg" role="document">
                <div class="modal-content">
                    <div data-cy="reserve_modal_title" class="modal-header hearder-custom">
                        <div>
                            <h4 class="modal-title">{{ t(modalTitle) }}</h4>
                            <button type="button" class="close close-custom" id="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body hearder-custom" v-if="wichModal == 3">
                        <p class="mb-3"><strong>Caso reserva possua proposta aprovada a mesma será rejeitada e suas
                            assinaturas eletrônicas canceladas</strong></p>
                        <div class="row gutters custom-margin">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label>{{ t('LOTS.ALLOTMENT') }}: </label>
                                    <label class="ml-1 value">{{ nameAllotment }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div class="form-group">
                                    <label>{{ t('LOTS.BLOCK') }}: </label>
                                    <label class="ml-1 value">{{ block }}</label>
                                </div>
                            </div>
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                                <div class="form-group">
                                    <label>{{ t('LOTS.LOT') }}: </label>
                                    <label class="ml-1 value">{{ lot }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label>{{ t('RESERVE.REALTOR') }}: </label>
                                    <label class="ml-1 value">{{ realtor }}</label>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="reason_revocation" class="required">Motivo para Interromper a
                                        reserva:</label>
                                    <textarea class="form-control" id="reason_revocation" cols="20" rows="10"
                                              v-model="reasonRevocation">
                                    </textarea>
                                    <p class="red">Mínimo 10 caracteres</p>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <button data-cy="reserve_modal_close" type="button"
                                    class="btn btn-secondary mr-1 width-buttons" data-dismiss="modal">
                                {{ t('ACTIONS.CLOSE') }}
                            </button>
                            <button data-cy="reserve_modal_confirm" type="button"
                                    class="btn btn-primary mr-1 width-buttons" @click="drop(reserve.id)">
                                {{ t('ACTIONS.CONFIRM') }}
                            </button>
                        </div>
                    </div>

                    <div class="modal-body hearder-custom" v-if="wichModal == 4">
                        <div class="row gutters ml-3">
                            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7">
                                <div class="form-group">
                                    <b>Corretor: </b>
                                    <p>{{ reserveIndex.realtor }}</p>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div class="form-group">
                                    <b>{{ t('RESERVE.STATUS') }}: </b>
                                    <p>{{ reserveIndex.reserve_status.name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters ml-3">
                            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7">
                                <div class="form-group">
                                    <b>{{ t('PROPERTIES.BLOCK') }}: </b>
                                    <p>{{ reserveIndex.properties.block }}</p>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div class="form-group">
                                    <b>{{ t('PROPERTIES.NAME') }}: </b>
                                    <p>{{ reserveIndex.properties.name }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive text-center">
                            <table class="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>{{ t('RESERVE.START_RESERVE') }}</th>
                                    <th>{{ t('RESERVE.FINISH_RESERVE') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="historic in histories" :key="historic.id">
                                    <td :style="{'font-weight': historic.active ? 'bold':'normal'}">
                                        {{ dateFormat(historic.start) }}
                                    </td>
                                    <td :style="{'font-weight': historic.active ? 'bold':'normal'}">
                                        {{ dateFormat(historic.finish) }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row gutters mt-2">
                            <div :class="reserveIndex?.reserve_status?.name === 'Interrompido' ?
                            'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12' :'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                 v-if="reserve?.observation">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">Observações:</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="10" disabled
                                              v-model="reserve.observation"></textarea>
                                </div>
                            </div>
                            <div :class="reserve?.observation ?
                            'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12' : 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                 v-if="reserveIndex.reserve_status.name === 'Interrompido'">
                                <div class="form-group">
                                    <label for="reason_revocation_history">Motivo para estar Interrompida:</label>
                                    <textarea class="form-control" id="reason_revocation_history" cols="20" rows="10"
                                              v-model="reasonRevocationShow" disabled>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <button type="button" class="btn btn-secondary mr-1 mt-2" data-dismiss="modal">
                                {{ t('ACTIONS.CLOSE') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import Reserve from "@/services/Reserves";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';

export default {
    name: 'dropReserve',
    mixins: [Permission, Validate],
    props: ['reserveIndex', 'modalTitle', 'wichModal'],

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    data() {
        return {
            reasonRevocation: null,
            reasonRevocationShow: null,
            lot: null,
            block: null,
            histories: [],
            realtor: null,
            reserve: null,
            properties: null,
            nameAllotment: null,
        }
    },

    mounted() {
        this.reasonRevocation = null;
        this.reasonRevocationShow = null;
        this.reserve = this.reserveIndex;
        this.realtor = this.reserve.realtor;
        this.properties = this.reserve.properties;

        this.lot = this.properties.name;
        this.block = this.properties.block;
        this.nameAllotment = this.properties.allotments.name;

        this.indexHistoric(this.reserveIndex.id, this.reserveIndex.properties.allotment_id);

    },

    watch: {
        reserveIndex: function () {
            this.reasonRevocation = null;
            this.reasonRevocationShow = null;
            this.histories = [];
            this.reserve = this.reserveIndex;
            this.properties = this.reserve.properties;

            this.lot = this.properties.name;
            this.block = this.properties.block;
            this.nameAllotment = this.properties.allotments.name;

            this.indexHistoric(this.reserveIndex.id, this.reserveIndex.properties.allotment_id);
        },
    },

    methods: {
        drop(reserve_id) {
            this.reasonRevocation = this.reasonRevocation?.trim();
            if (!this.reasonRevocation?.length || this.reasonRevocation?.length < 10) {
                this.toast.error('Necessário informar a razão que levou a interromper esta reserva; Com no mínimo 10 caracteres.');
                return;
            }
            this.$store.commit('changeLoading', true);
            Reserve.drop(reserve_id, this.reserve.properties.allotment_id, this.reasonRevocation).then(() => {
                document.getElementById('closeModal').click();
                this.toast.success(this.t('GENERAL.MSG.RESERVE_DROP_SUCCESSED'));
                this.$emit('drop');
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error);
                this.$store.commit('changeLoading', false);
            });
        },

        indexHistoric(reserveId, allotment_id) {
            Reserve.historic(reserveId, allotment_id).then(resp => {
                let reserve = resp.data.reserve;
                delete resp.data.reserve;
                this.reasonRevocationShow = reserve.reason_revocation;
                this.histories = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error);
                this.$store.commit('changeLoading', false);
            });
        },

        dateFormat(value) {
            if (value) {
                let onlyData = value.split(' ');
                let data = onlyData[0].split('-');

                value = data[2] + '/' + data[1] + '/' + data[0] + ' ás ' + onlyData[1];

                let time = value.split(':');

                return time[0] + ":" + time[1];
            } else {
                return '?';
            }
        }
    }
}
</script>

<style>
.right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end !important;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.name {
    margin: auto !important;
    border-radius: 3px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.full-border {
    border-radius: 2px !important;
    border-bottom: 1px solid #dbd4d4 !important;
    border-top: 1px solid #dbd4d4 !important;
    border-left: 1px solid #dbd4d4 !important;
    border-right: 1px solid #dbd4d4 !important;
}

.hearder-custom {
    color: #333333 !important;
    background: #ffffff !important;
}

.close-custom {
    color: #000 !important;
}

.modal-title {
    padding-bottom: 1rem;
}

.value {
    font-size: .8rem;
    font-weight: 400;
}

.custom-margin {
    height: 24px !important;
}

.width-buttons {
    width: 30%;
}

.margin-historic {
    padding-top: 1rem;
    padding-left: 1rem;
}

.border-red {
    border-color: #d64c36;
}

.card-custom {
    margin-bottom: 0rem;
}

.mt-custom {
    margin-top: 1rem;
}

.margin-historic {
    padding-top: 1rem;
    padding-left: 1rem;
}

.modal-header {
    padding-bottom: 0em !important;
}

.modal-body {
    padding-top: 0em !important;
}

.card-info {
    padding: 0.5rem;
    font-size: .840rem;
}

.user-card {
    padding-top: 0.1em !important;
    padding-bottom: 0.5em !important;
}

.alin-between {
    justify-content: space-between !important;
}
</style>
